<template>
	<div class="spot">
		<headerNav :activeIndex="2" />
    <div class="spotBody">
      <div class="spotBodyItem">
         <div style="display: flex">
           <div class="spotBodyImg">宝汇</div>
           <div class="spotBodyText">
             <div class="title">浙江宝汇大宗供应链有限公司</div>
             <div class="titleText">经营范围：建筑钢材、卷板</div>
           </div>
         </div>
          <div class="xian"></div>
          <div class="spotBodyDiv">
            <div style="display: flex;align-items:center;font-weight: 600;"><div class="spotBodyDivBg"></div>建材部</div>
            <div>刘冉:<span style="color:#0285ED;margin-right:30px">17721520609</span>张功胜:<span style="color:#0285ED;margin-right:30px">17368716256</span></div>
            <div>联系地址：江苏南京玄武区红山路星河world 3栋611室</div>
          </div>
          <div class="spotBodyDiv">
            <div style="display: flex;align-items:center;font-weight: 600;"><div class="spotBodyDivBg"></div>卷板部</div>
            <div>徐立:<span style="color:#0285ED;margin-right:30px">15058488137</span></div>
            <div>联系地址：浙江宁波江北区城北路钢铁智慧港2008室</div>
          </div>
        <div class="spotBodyDiv">
          <div style="display: flex;align-items:center;font-weight: 600;"><div class="spotBodyDivBg"></div>品规</div>
          <div>萍钢、永钢、螺纹钢 ：12*9/14*9/16*9/18*9/20*9/22*9/25*9/28*9/32*9</div>
          <div>盘螺 ：6/8/10</div>
          <div>卷板：（3.5-9.25)*1500*C</div>
        </div>
      </div>


      <div class="spotBodyItem">
        <div style="display: flex">
          <div class="spotBodyImg">晨华</div>
          <div class="spotBodyText">
            <div class="title">浙江晨华供应链有限公司</div>
            <div class="titleText">经营范围：建筑钢材</div>
          </div>
        </div>
        <div class="xian"></div>
        <div class="spotBodyDiv">
          <div style="display: flex;align-items:center;font-weight: 600;"><div class="spotBodyDivBg"></div>永钢经营部</div>
          <div>陈珊珊:<span style="color:#0285ED;margin-right:30px">18961661392</span>晏云同:<span style="color:#0285ED;margin-right:30px">13921159296</span></div>
          <div>联系地址：江苏省无锡市惠山区林新路2号星富国际1205</div>
        </div>
        <div class="spotBodyDiv">
          <div style="display: flex;align-items:center;font-weight: 600;"><div class="spotBodyDivBg"></div>萍钢经营部</div>
          <div>姜玲玲:<span style="color:#0285ED;margin-right:30px">18616358065</span>廖木彬:<span style="color:#0285ED;margin-right:30px">15800615638</span></div>
          <div>联系地址：上海市宝山区友谊路1588弄钢领8号楼401-402室</div>
        </div>
        <div class="spotBodyDiv" style="width:1200px">
          <div style="display: flex;align-items:center;font-weight: 600;"><div class="spotBodyDivBg"></div>品规</div>
          <div>萍钢、永钢、中天、新三洲、螺纹钢 ：12*9/14*9/16*9/18*9/20*9/22*9/25*9/28*9/32*9</div>
          <div>盘螺：6/8/10</div>
        </div>
      </div>
    </div>
		<footerNav />
	</div>
</template>

<script>
	import footerNav from "../../components/footerNav.vue";
	import headerNav from "../../components/headerNav.vue";
	export default {
		name: "home",
		components: {
			footerNav,
			headerNav
		},
		data() {
			return {

			};
		},
		mounted() {

		},
		methods: {


		},
	};
</script>

<style lang="scss" scoped>
	.spot {
		min-width: 100vw;
		min-height: 100vh;
		text-align: center;
	}
  .spotBody{
    width: 1200px;
    margin:0px auto
  }
  .spotBodyItem{
    width: 1140px;
    padding: 30px;
    padding-bottom:0px;
    background: linear-gradient( 180deg, #F0F9FF 0%, #FFFFFF 100%);
    border-radius: 4px;
    border: 1px solid #EFEFEF;
    filter: blur(0px);
    margin:0px auto;
    margin-bottom: 30px;
    text-align: left;

  }
  .spotBodyImg{
    width: 80px;
    height: 80px;
    background: #FBD889;
    border-radius: 4px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 80px;
    text-align: center;
    font-style: normal;
  }
  .spotBodyText{
    margin-left: 20px;
  }
  .spotBodyText .title{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #000102;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .spotBodyText .titleText{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-bottom: 12px;
    display: flex;
    align-items: center
  }
  .spotBodyItem .xian{
    width: 100%;
    border: 1px dashed #D1D0D0;
    margin: 30px auto;
  }
  .spotBodyDiv{
    width:570px;
    display: inline-block;
    text-align: left;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    font-style: normal;
    margin-bottom: 30px;
    div{
      margin-bottom: 12px;
    }
    .spotBodyDivBg{
      width: 2px;
      height: 12px;
      background: #7BC1F8;
      margin-bottom: 0px;
      margin-right: 10px;
    }
  }
</style>