<template>
	<div>
		<div class="headBody">
			<div class="logo" @click="handleSelect(1)">
				<img src="../assets/img/logo.png" style="width: 113px;height: 29px;" alt="至刚网" />
			</div>
			<div class="header-nav-search">
				<div class="nav-search-input">
					<el-input placeholder="输入品名、材质等关键词" v-model="searchWord" class="input-with-select">
						<el-button slot="append" icon="el-icon-search" class="input-with-button"
							@click="handleSelect(2)"></el-button>
					</el-input>
				</div>
				<div class="nav-search-keyword">
					<ul>
						<li v-for="(item, index) in searchKeyWord" :key="index" @click="handleSelect(2)">
							{{ item }}
						</li>
					</ul>
				</div>
			</div>
			<div class="header-nav-buttons">
				<div class="toBuy " @click="handleSelect(2)">
					我要求购
					<img src="../assets/img/toBuy.png" alt="至刚网" style="width:15px;height:17px;margin-left:7px;" />
				</div>
			</div>
		</div>
		<div class="menu">
			<div class="menuText" :style="this.activeIndex == 1 ? {color:'#0285ED'} : ''" @click="handleSelect(1)">首页</div>
			<div class="menuText" :style="this.activeIndex == 2 ? {color:'#0285ED'} : ''" @click="handleSelect(2)">现货资源
			</div>
			<div class="menuText" :style="this.activeIndex == 3 ? {color:'#0285ED'} : ''" @click="handleSelect(3)">供应链服务
			</div>
			<div class="menuText" :style="this.activeIndex == 4 ? {color:'#0285ED'} : ''" @click="handleSelect(4)">关于我们
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: "headerNav",
		props: {
			activeIndex: String,
		},
		data() {
			return {
				searchKeyWord: ['螺纹钢', '盘螺', '热轧卷板', '中厚板'],
			};
		},
		methods: {
			handleSelect(item) {
				this.activeIndex = item;
				if (item == 1) {
					this.$router.push({
						name: "home",
					});
				} else if (item == 2) {
					this.$router.push({
						name: "spot",
					});
				} else if (item == 3) {
					let routeData = this.$router.resolve({
						path: '/supply/index'
					});
					window.open(routeData.href, '_blank');
				} else if (item == 4) {
					this.$router.push({
						name: "aboutUs",
					});
				}
			},
		},
	};
</script>
<style lang="scss">
	.headBody {
		margin: 0 auto;
		width: 1200px;
		height: 105px;
		display: flex;


		.logo {
			height: 105px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			cursor: pointer;
			user-select: none;
			margin-right: 184px;
		}

		.header-nav-search {
			height: 105px;
			display: flex;
			justify-content: center;
			flex-direction: column;
		}

		.nav-search-input {
			width: 534px !important;
			height: 36px !important;
			margin-top: 20px;
		}

		.input-with-select input {
			border-radius: 2px !important;
			border: 1px solid #3B74F1 !important;
			border-right: none !important;
			height: 36px !important;
			line-height: 36px !important;
		}

		.el-input-group__append {
			background-color: #3B74F1 !important;
			border-color: #3B74F1 !important;
			cursor: pointer;
		}

		.input-with-button {
			height: 36px !important;
			color: #ffffff !important;
			background-color: #3B74F1 !important;
			border-color: #3B74F1 !important;
			cursor: pointer;
		}

		.nav-search-keyword ul {
			display: flex;
			list-style: none;
			font-family: MicrosoftYaHei;
			color: #999999;
			font-size: 12px;
			margin-top: 5px;
		}

		.nav-search-keyword ul li {
			margin-left: 11px;
			cursor: pointer;
		}

		.nav-search-keyword ul li:hover {
			color: #578BFF;
		}

		.header-nav-buttons {
			height: 103px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 20px;
		}

		.toBuy {
			width: 180px;
			height: 36px;
			background: linear-gradient(138deg, #578BFF 0%, #3B74F1 100%);
			text-align: center;
			line-height: 35px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 15px;
			color: #FFFFFF;
			text-align: center;
			font-style: normal;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

	}

	.menu {
		width: 1200px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin: 0 auto;
		margin-top: 13px;
		margin-bottom: 24px;

		.menuText {
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 15px;
			color: #0A1924;
			line-height: 21px;
			text-align: left;
			font-style: normal;
			cursor: pointer;
			user-select: none;
			margin-right: 130px;

		}

	}
</style>